.templateContainer {
  /*   border: 1px solid black; */
  padding: 2px;
  min-height: 400p;
}

.mapPopup {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 5;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 50%;
  height: 50%;
}

.mapPopupMain {
  position: fixed;
  padding: 0;
  margin: 0;
  z-index: 40;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.close {
  filter: alpha(opacity=20);
  opacity: 1 !important;
}

.firstSelect {
  width: 20%;
}

.container {
  min-width: 320px;
}

.list {
  margin: 0 auto;
  padding: 15px 20px;

  width: 100%;
  max-width: 290px;
  list-style: none;
  /*   align-items: center; */
  justify-content: center;
}

/* .item:last-child {
  margin-bottom: 0;
} */

.btn,
.activeBtn {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 5px 10px;
  height: 35px;

  border: 1px solid #30ad64;
  border-radius: 5px;

  font-size: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.btn {
  color: #676770;
  background-color: inherit;
  cursor: pointer;
}

.btn:hover {
  color: #ffffff;
  background-color: #4db6ac;
}

.activeBtn {
  color: #ffffff;
  background-color: #4db6ac;
}

.iconContainer {
  margin: 0;
  margin-right: 5px;
  padding: 4px;

  width: 20px;
  height: 20px;

  background-color: #4db6ac;
  border-radius: 50%;
}

.iconContainer svg {
  width: 12px;
  height: 12px;

  fill: #ffffff;
}

.custom_select {
  /* Remove default select arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: white;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 24'%3E%3Cpath fill='hsl(0, 0%, 80%)' d='M 29.338 7.804 C 29.774 7.358 30.381 7.323 30.914 7.804 L 34.822 11.551 L 38.73 7.804 C 39.263 7.323 39.871 7.358 40.304 7.804 C 40.74 8.249 40.712 9.001 40.304 9.419 C 39.898 9.837 35.609 13.921 35.609 13.921 C 35.392 14.144 35.107 14.256 34.822 14.256 C 34.537 14.256 34.252 14.144 34.033 13.921 C 34.033 13.921 29.746 9.837 29.338 9.419 C 28.93 9.001 28.902 8.249 29.338 7.804 Z'%3E%3C/path%3E%3Crect x='14.003'  width='1' height='20.605' style='stroke: rgb(0, 0, 0); fill-rule: nonzero; paint-order: stroke markers; fill: rgb(204, 204, 204); stroke-width: 0px;'/%3E%3C/svg%3E");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 3em 1.5em;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  padding: 0.375rem 3rem 0.375rem 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.editorStyle {
  color: black;
  font-weight: normal;
  margin-left: 15px;
  margin-right: 15px;
  border-bottom: 1px solid #4db6ac;
  z-index: 1;
}

.customToolbar {
  position: sticky;
  top: 45px;
  z-index: 2;
  background: white;
  max-width: 100%;
}

.selectD {
  display: flex;
  vertical-align: middle;
  align-items: center;
  margin-left: 3px;
  position: sticky;
  top: 2px;
  z-index: 2;
  background: white;
  max-width: 100%;
  padding-top: 5px;
  box-shadow: 0 0 0 3px white;
}

.topSaveBtn {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  background-color: #4db6ac;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 2px;
  text-align: center;
  padding-top: 10px;
  padding-right: 30px;
  padding-bottom: 10px;
  padding-left: 30px;
  margin-left: auto;
}

.topSaveBtn:disabled {
  cursor: not-allowed;
  background-color: #b0bec5; /* A lighter, disabled-looking color */
  color: #eceff1; /* Light text color for contrast */
  opacity: 0.6; /* Slightly transparent to indicate it's disabled */
}